<template>
  <v-card elevation="1" tile class="pa-5">
    <div class="subtitle-1 font-weight-bold mb-5">RECLAMOS PRORROGABLES</div>

    <DataTables
      :claims="claims"
      :loading="page.loading"
      :changePageFilter="true"
      @gotoClaim="gotoClaim"
      @changePage="changePage"
      @search="search"
    />
  </v-card>
</template>

<script>
import baseViewMixin from "@/mixins/baseView";
import parametersMixin from "@/mixins/parameters";
import currentUserMixin from "@/mixins/currentUser";
import currentClaimSelectedMixin from "@/mixins/currentClaimSelected";

import claimsService from "@/services/claims";

import DataTables from "@/components/WFSite/Claims/DataTables";

export default {
  mixins: [
    baseViewMixin,
    parametersMixin,
    currentUserMixin,
    currentClaimSelectedMixin,
  ],
  components: { DataTables },
  data() {
    return {
      claims: [],
    };
  },
  methods: {
    async gotoClaim(selectedClaimInfo) {
      await this.loadClaimForWf(
        selectedClaimInfo.claimId,
        selectedClaimInfo.userIdentification,
        this.$route.name
      );
      this.gotoView("claimDetailInWf");
    },
    async getClaims(requiredPageNumber, filter) {
      this.initSignalLoading();

      let pageNumber = requiredPageNumber ? requiredPageNumber : 1;

      const getResult = await claimsService.getByWfStatusesV2(
        this.allConstants.dashboard.groups.bankAdministrator.claimsInExtension
          .wfStateType,
        pageNumber,
        15,
        "",
        filter
      );
      this.claims = getResult.data.claims;

      const currentPageData = this.currentPage;
      currentPageData.routerName = this.$route.name;
      currentPageData.pageNumber = pageNumber;
      currentPageData.totalPages = getResult.data.pagesTotal;
      currentPageData.totalRecords = getResult.data.recordsTotal;
      this.createOrUpdateCurrentPage(currentPageData);

      this.stopSignalLoading();
    },
    search(filter) {
      this.claims = [];
      this.getClaims(1, filter);
    },
    changePage(change) {
      this.claims = [];
      this.getClaims(change.toPageNumber, change.filter);
    },
  },
  beforeMount() {
    let pageNumber = 1;
    if (this.currentPage.routerName == this.$route.name)
      pageNumber = this.currentPage.pageNumber ?? 1;

    this.getClaims(pageNumber, []);
  },
};
</script>

<style lang="scss" scoped></style>
